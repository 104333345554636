import React from "react";
import { Button, TextArea } from "@blueprintjs/core";

type CustomTextFieldProps = {
    name: string,
    placeholder: string,
    onChange: React.FormEventHandler<HTMLElement>
}

const CustomTextField = (props: CustomTextFieldProps) => {
    return (
        <TextArea
            //label={props.label}
            name={props.name}
            placeholder={props.placeholder}
            large={true}
            onChange = {props.onChange}
        />
    );
}

export default CustomTextField