import React, {useState, useEffect} from 'react';
import './App.css';

import CustomTextField from "./CustomTextField";
import { Button, TextArea} from "@blueprintjs/core";
import axios from 'axios';
import axiosRetry from 'axios-retry';
import CircleLoader from "react-spinners/BarLoader";
import { NavLink } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { isPropertySignature } from 'typescript';


interface IPost {
  label: string;
  score:number
}

interface IError {
  error: string
}

type Values = {
  sentence: string,
  replyText: string[],
  loading:Boolean
}

function ExamplePage() {

  const [values,setValues] = useState<Values>({sentence: "", replyText: [], loading: false});

  const handleChange = (event : React.ChangeEvent<HTMLInputElement>) => {
      //console.log(event.target)
      setValues({...values,[event.target.name] : event.target.value});
  }

  const responseText = (response: IPost[], sentence: string): string => {

    const getStr = (sentence: string): string => {
      if (sentence.includes(" de ")){
        return "Ersätt de med \"dem\""
      }else if(sentence.includes(" dem ")){
        return "Ersätt dem med \"de\""
      }else if (sentence.includes("De ")){
        return "Ersätt De med \"Dem\""
      }else if (sentence.includes("Dem ")){
        return "Ersätt Dem med \"De\""
      }else if (sentence.includes(" de.")){
        return "Ersätt de med \"dem\""
      } else if (sentence.includes(" dem.")){
        return "Ersätt dem med \"de\""
      }

      return "error"
    }

    const maxLabel = response.reduce((prev, current) => (prev.score > current.score) ? prev : current ).label
    
    switch (maxLabel) {
      case "LABEL_0": return "Meningen är korrekt!"
      case "LABEL_1": return getStr(sentence)
    }
    console.log(maxLabel)
    return "error"
  }

  const fixSentence = (sentence: string) => {
    return sentence.endsWith('.') ? sentence : sentence + "." 
  }

  const handleSubmit = (sentence: string, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    //console.log("In handle submit")

    if (sentence.length == 0){
      return
    }

    sentence = fixSentence(sentence)

    if (sentence.length > 100){
      setValues({...values, replyText: ["Meningen är för lång!"]})
      return 
    }

    setValues({...values, loading: true, replyText: []})
   
    axiosRetry(axios, {
      retries: 10, // number of retries
      retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return 4000; // time interval between retries
      },
      retryCondition: (error) => {
        // if retry condition is not specified, by default idempotent requests are retried
        return true;
      },
    });

    axios.post<IPost[][]>("https://api-inference.huggingface.co/models/eliash/dom", {"inputs": sentence}, {
      headers: {
        "Authorization": "Bearer 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",
      },}).then(r => setValues({...values,replyText: [responseText(r.data[0], sentence)], loading: false}),
          _ => setValues({...values,replyText: ["Error, please wait and try again"], loading: false})
        )
    //console.log(values.requestResponse);
  }


  useEffect(() => {
    const element = document.getElementsByTagName("META")[1];

    document.title = `Testa om din mening som inehåller de eller dem är korrekt`;

    if (element instanceof HTMLMetaElement) {
      element.content="Testa om din mening som inehåller de eller dem är korrekt. Bara skriv in din mening och få svar direkt";
    }
  });

  return (
    <div className="App">
      <header className="App-header">
        <h1>Skilj mellan de och dem automatiskt</h1>
        <p>
          Att skilja på <><strong>de</strong></> och <strong>dem</strong> kan vara svårt. Därför har vi skapat en tjänst som automatiskt testar om du har använt  <><strong>de</strong></> eller <strong>dem</strong> rätt. 
          Bara skriv in din mening i rutan nedan och tryck på knappen så testar vi automatiskt om du använt  <><strong>de</strong></> och <strong>dem</strong> rätt!
        </p>
        <p>
        <CustomTextField name = "sentence" placeholder = "Skriv in din mening" onChange={handleChange} />
        </p>
        <p>
        <Button intent="success" text="Kontrollera mening" onClick={event => handleSubmit(values.sentence, event)} active={true} />
        </p>
        <p>
         {<CircleLoader color={'white'} loading={values.loading == true}/>}
         {values.replyText.map((r: string) => r)}
        </p>
        <h1>De eller dem, det är frågan</h1>
        <p>
          Det finns olika regler för att skilja de och dem åt. Ett exempel är att <a href="https://panang.se/tips-guider/sa-enkelt-skiljer-du-mellan-de-och-dem/">byta ut de och dem mot mig och dig.</a>
          <p>
          En annan regel är att <a href="https://hannalundquist.se/sprakkronika-sa-skiljer-du-pa-de-och-dem/">byta ut de och dem till they them och the.</a>
          </p>
          Men ingen regel fungerar i 100% av fallen. Är du osäker är det därför lättare att testa din mening i textrutan ovan.
        </p>
        <h1>Hur bra fungar det?</h1>
        <p>
          Vi har testat vår algoritm på stora mängder av text. Men såklart kan det trots detta ha smygit sig in fel. 
          Om du hittar ett fel är vi tacksama om du kontaktar oss via <a href = "mailto: grammatikfeedback@gmail.com">mail</a>, så kommer vi rätta till problemet så fort så möjligt. 
          Tilsammans kan vi göra tjänsten ännu bättre.
        </p>
      </header>
    </div>
  );
}


const App = () => (
  <div className='app'>
    <Main />
  </div>
);

export default App;

//export ExamplePage;

const Navigation = () => (
  <nav>
    <ul>
      <li><NavLink to='/skilj-mellan-de-och-dem/'>Skilj mellan de och dem</NavLink></li>
    </ul>
  </nav>
);


const Main = () => (
  <Routes>
    <Route path='/' element={<Home/>} />
    <Route path='/skilj-mellan-de-och-dem-automatiskt/' element={<ExamplePage/>}/>
  </Routes>
);

function Home() {
  useEffect(() => {
    const element = document.getElementsByTagName("META")[1];

    document.title = `Grammatiken | Förbättra din grammatik`;

    if (element instanceof HTMLMetaElement) {
      element.content="Vi utvecklar verktyg för att hjälpa dig skriva grammatiskt korrekt.";
    }

  });

  return (<div className="App">
  <header className="App-header">
    <h1>Välkommen till Grammatiken</h1>
    <p>
      Vi utvecklar verktyg för att hjälpa dig skriva grammatiskt korrekt. 
    </p>
    <li><NavLink to='/skilj-mellan-de-och-dem-automatiskt/'>Skilj mellan de och dem automatiskt</NavLink></li>
    </header>
    </div>
);}

